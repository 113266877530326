import type { ResponseError } from 'nuxt-strict-fetch/types';

import type { UserType } from '~/types/users';

export const useMainStore = defineStore('main', () => {
  const firebase = useFirebaseStore();
  const router = useRouter();
  // TODO: костыль
  const route = useRoute();
  const authorized = useCookie('authorized');

  const user = ref({} as UserType);
  const isAuthorized = ref(false);
  const isLogout = ref(false);
  const notification = ref(false);

  const loaded = computed(() => !!user.value.id);

  const setUser = (value: UserType) => {
    user.value = value;
    isAuthorized.value = true;
    isLogout.value = false;
  };

  const logout = (clear = false) => {
    UserAPI.logout().then(() => {
      if (!clear) router.push('/');

      Promise.resolve().then(() => {
        authorized.value = null;
        user.value = {} as UserType;
        isAuthorized.value = false;
        isLogout.value = true;
      });
    });
  };

  const loadUser = () => {
    return UserAPI.details()
      .then((data) => {
        setUser(data);
        if ('Notification' in window && Notification.permission === 'granted')
          firebase.update();
      })
      .catch((e: ResponseError) => {
        if (e.context?.response?.status === 401) return logout(true);
      });
  };

  const saveWantSex = () => {
    sendMetric(MetricEvent.wantSex, { value: user.value.wantSex });
    return UserAPI.changeWantSex({ body: { wantSex: user.value.wantSex } });
  };

  const isPWA = computed(() =>
    import.meta.server
      ? false
      : navigator.standalone ||
        window.matchMedia('(display-mode: standalone)').matches,
  );

  return {
    user,
    authorized,
    isAuthorized,
    isLogout,
    notification,
    isPWA,

    loaded,

    setUser,
    logout,
    loadUser,
    saveWantSex,

    route,
  };
});
